import React, { Component } from 'react';
import Navigation from './components/navigation';
import About from './components/about';
import Header from './components/header';
import Services from './components/services';
import Contact from './components/contact';
import {GoogleReCaptchaProvider} from 'react-google-recaptcha-v3';


export class App extends Component {

    render() {
        return (
            <div>
                <Navigation />
                <Header />
                <About />
                <Services />
                <Contact />
            </div>
        )
    }
}

export default App;