import React, { Component } from 'react'

export class about extends Component {
  render() {
    return (
        <div id="about">
        <div className="container">
          <div className="row">
            <div className="col-xs-12 col-md-6"> <img src="img/about-photo.jpg" className="img-responsive" alt=""/> </div>
            <div className="col-xs-12 col-md-6">
              <div className="about-text">
                <h2>About Us</h2>
                <h3>Why Choose Us?</h3>
                <p>
                    Devsio started during the great pandemic of 2020. Suddenly there was a need for a lot of companies to have a digital presence, and thus Devsio began.
                    We specialise in Mobile, Web and application development. With experience in several industries including Financial tech locally and abroad.
                </p>
                <div className="list-style">
                  <div className="col-lg-6 col-sm-6 col-xs-12">
                    <ul>
                        <li>
                            One stop shop
                        </li>
                        <li>
                            Experienced in our industry
                        </li>
                    </ul>
                  </div>
                  <div className="col-lg-6 col-sm-6 col-xs-12">
                    <ul>
                        <li>
                            Great Client Service
                        </li>
                        <li>
                            Fast and efficient
                        </li>
                    </ul>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    )
  }
}

export default about