import React, { Component } from "react";

export class Services extends Component {
	render() {
		return (
			<div id="services" className="text-center">
				<div className="container">
					<div className="section-title">
						<h2>Our Services</h2>
						<p>
							Please feel free to contact us for any software related services.
						</p>
					</div>
					<div className="row">
						<div class="col-xs-6 col-md-3">
							<i class="fa fa-wrench"></i>
							<h3>Maintenance</h3>
							<p>
								If you have any existing projects you would like to do maintenance or enhancements on, we are able to assist.
							</p>
						</div>
						<div class="col-xs-6 col-md-3">
							<i class="fa fa-ticket"></i>
							<h3>Support</h3>
							<p>
								24/7 Client Support 
							</p>
						</div>
						<div class="col-xs-6 col-md-3">
							<i class="fa fa-file-text-o"></i>
							<h3>New Development</h3>
							<p>
								New development as per client requirements.
							</p>
						</div>
						<div class="col-xs-6 col-md-3">
							<i class="fa fa-envelope-o"></i>
							<h3>Hosting</h3>
							<p>
								Website hosting and domain registration for your perfect website.
							</p>
						</div>
					</div>
				</div>
			</div>
		);
	}
}

export default Services;