import React, { Component } from "react";

export class Contact extends Component {
  constructor(props) {
    super(props);
    this.state = { data: [], loading: true };
  }

  render() {
    return (
      <div>
        <div id="contact">
          <div className="container">
            <div className="col-md-8">
              <div className="row">
                <div className="section-title">
                  <h2>Get In Touch</h2>
                  <p>
                    Please fill out the form below to send us an email and we
                    will get back to you as soon as possible.
                  </p>
                </div>
                <form name="sentMessage" id="contactForm" noValidate>
                  <input type="hidden" id="recap" />
                  <div className="row">
                    <div className="col-md-6">
                      <div className="form-group">
                        <input
                          type="text"
                          id="name"
                          className="form-control"
                          placeholder="Name"
                          required="required"
                        />
                        <p className="help-block text-danger"></p>
                      </div>
                    </div>
                    <div className="col-md-6">
                      <div className="form-group">
                        <input
                          type="email"
                          id="email"
                          className="form-control"
                          placeholder="Email"
                          required="required"
                        />
                        <p className="help-block text-danger"></p>
                      </div>
                    </div>
                    <div className="col-md-12">
                      <div className="form-group">
                        <input
                          type="text"
                          id="subject"
                          className="form-control"
                          placeholder="Subject"
                          required="required"
                        />
                        <p className="help-block text-danger"></p>
                      </div>
                    </div>
                  </div>
                  <div className="form-group">
                    <textarea
                      name="message"
                      id="message"
                      className="form-control"
                      rows="4"
                      placeholder="Message"
                      required
                    ></textarea>
                    <p className="help-block text-danger"></p>
                  </div>
                  <div id="success"></div>
                  <input type="submit" 
                  id="submit" 
                  className="btn btn-custom btn-lg"
                  value="send" value="Send Message"/>
                </form>
              </div>
            </div>
            <div className="col-md-3 col-md-offset-1 contact-info">
              <div className="contact-item">
                <h3>Contact Info</h3>
                <p>
                  <span>
                    <i className="fa fa-map-marker"></i> Address
                  </span>
                  5 Van Beek Avenue <br />
                  Unit 7 La Bonne Vie <br />
                  Flamwood <br />
                  Klerksdorp
                </p>
              </div>
              <div className="contact-item">
                <p>
                  <span>
                    <i className="fa fa-phone"></i> Phone
                  </span>{" "}
                  (+27)73 393 4938
                </p>
              </div>
              <div className="contact-item">
                <p>
                  <span>
                    <i className="fa fa-envelope-o"></i> Email
                  </span>{" "}
                  sharding@devs-io.co.za
                </p>
              </div>
            </div>
          </div>
        </div>

        <div id="footer">
          <div className="container text-center">
            <p>
              &copy; 2021 Devsio. Designed and developed by{" "}
              <a href="https://devs-io.co.za" rel="nofollow">
                Devs-io
              </a>
            </p>
          </div>
        </div>
      </div>
    );
  }
}



export default Contact;
